import axios from 'axios';

const clearProductState = (data = null) => {
    return {
        type: 'CLEAR_PRODUCT_STATE',
        payload: data,
    };
};
const setProducts = (
    products,
    cats,
    total,
    loading,
    productsFilterQuery,
    selectedCat
) => {
    return {
        type: 'GET_PRODUCTS',
        payload: {
            products,
            cats,
            total,
            loading,
            productsFilterQuery,
            selectedCat,
        },
    };
};
const setProductsFilterQuery = (query) => {
    return {
        type: 'SET_PRODUCT_FILTER_QUERY',
        payload: query,
    };
};
const menuToggleAllProducts = () => {
    return {
        type: 'MENU/TOGGLE_ALL_PRODUCTS',
    };
};
const toggleOrders = () => {
    return async (dispatch, getState) => {
        if (
            Object.keys(getState().orderReducer?.products).length ||
            getState().productReducer?.ordersShown === true
        ) {
            await dispatch(toggleOrdersAct());
        }
        await dispatch({ type: 'MENU/HIDE_ALL_PRODUCTS' });
    };
};
const toggleOrdersAct = () => {
    return {
        type: 'TOGGLE_ORDERS',
    };
};

const showOrdersAct = () => {
    return {
        type: 'SHOW_ORDERS',
    };
};
const showOrders = () => {
    return async (dispatch, getState) => {
        if (
            Object.keys(getState().orderReducer?.products).length ||
            getState().productReducer?.ordersShown !== true
        ) {
            await dispatch(showOrdersAct());
        }
        await dispatch({ type: 'MENU/SHOW_ALL_PRODUCTS' });
    };
};

const hideOrdersAct = () => {
    return {
        type: 'HIDE_ORDERS',
    };
};
const hideOrders = () => {
    return async (dispatch, getState) => {
        if (
            Object.keys(getState().orderReducer?.products).length ||
            getState().productReducer?.ordersShown === true
        ) {
            await dispatch(hideOrdersAct());
        }
        await dispatch({ type: 'MENU/HIDE_ALL_PRODUCTS' });
    };
};
const filterProducts = (query) => {
    return getProducts(null, 'bg', query);
};
const getProducts = (categoryId = 0, locale = 'bg', query = '') => {
    return async (dispatch, getState) => {
        await dispatch({ type: 'MENU/HIDE_ALL_PRODUCTS' });
        await dispatch(
            setProducts(
                [],
                getState()?.productReducer?.cats,
                getState()?.productReducer?.total ?? 0,
                true
            )
        );
        try {
            if (query.length < 1 && categoryId === null) {
                categoryId = getState()?.productReducer?.selectedCat;
            } else if (categoryId === null) {
                categoryId = 0;
            }
            
            const is_opt = getState()?.authReducer.user.is_opt;

            const url = `${global.API_ADDRESS}/api/products/${categoryId}`;
            let res = await axios
                .post(url, { locale, query, is_opt })
                .catch((error) => {
                    console.log(error);
                    global.Alert(error.toString());
                });
            await dispatch(
                setProducts(
                    res?.data?.products,
                    res?.data?.cats,
                    0,
                    false,
                    query,
                    categoryId !== 0 ? categoryId : null
                )
            );
            return res?.data || [];
        } catch (error) {
            global.Alert(error.toString());
            console.error(error);
        }
    };
};

export default {
    getProducts,
    toggleOrders,
    hideOrders,
    filterProducts,
    menuToggleAllProducts,
    setProductsFilterQuery,
    showOrders,
    clearProductState,
};
